@font-face {
  font-family: Poppins-Regular;
  src: url(./shared/font/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(./shared/font/Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins-Italic;
  src: url(./shared/font/Poppins-Italic.ttf);
}

body {
  margin: 0;
  font-family: "Poppins-Regular" !important;
}
